<template>
  <div>
    <!-- Start leftpart -->
    <div class="leftpart">
      <div class="leftpart_inner">
        <!-- End .logo -->
        <div class="menu"
        >
          <a v-for="(tab, index) in tabs" :key="index"
             v-on:click=setActiveIndex(tab.index)
             :class="[activeTab === tab.index ? 'active' : '']"
          >
            <img
                class="svg"
                :src="tab.icon"
                :alt="tab.alt"
            />
            <span class="menu_content">{{ tab.name }}</span></a
          >
          <!-- End Home -->
          <!-- End History -->
        </div>
        <div class="copyright">
          <p>
            &copy; {{ new Date().getFullYear() }} YUENA <br/> Unofficial Fansite<br/>by
            <a
                href="http://twitter.com/taimiso0319"
                target="_blank"
                rel="noopener noreferrer"
            >taimiso</a>
          </p>
        </div>
        <!-- End copyright -->
      </div>
    </div>
    <!-- End leftpart -->

    <!-- Start rightpart -->
    <div class="rightpart">
      <div class="rightpart_in">
        <div class="tokyo_tm_section">
          <div :class="['container']">
            <div v-for="(tab, index) in tabs" :key="index"
                 class="tabcontent">
              <component :is="tab.component" v-if="activeTab === tab.index"></component>
            </div>
            <!-- End Contact tabcontent -->
          </div>
        </div>
      </div>
    </div>
    <!-- End rightpart -->
  </div>
</template>

<script>
import Home from "../components/Home";
import About from "../components/About";
import Portfolio from "../components/Portfolio";
import News from "../components/News";
import History from "../components/History"

export default {
  components: {
    Home,
    About,
    News,
    Portfolio,
    History,
  },
  data() {
    return {
      activeTab: 1,
      tabs: [
        {
          index: 1,
          name: "Home",
          component: Home,
          icon: require("../assets/img/svg/home-run.svg"),
          alt: "homerun",
        },
        {
          index: 2,
          name: "About",
          component: About,
          icon: require("../assets/img/svg/avatar.svg"),
          alt: "avatar",
        },
        {
          index: 3,
          name: "News",
          component: News,
          icon: require("../assets/img/svg/paper.svg"),
          alt: "paper",
        },
        {
          index: 4,
          name: "Works",
          component: Portfolio,
          icon: require("../assets/img/svg/briefcase.svg"),
          alt: "briefcase",
        },
        {
          index: 5,
          name: "History",
          component: History,
          icon: require("../assets/img/svg/timeline.svg"),
          alt: "timeline",
        },
      ],
    };
  },
  methods: {
    setActiveIndex: function (index) {
      this.activeTab = index;
    }
  }
};
</script>

<style lang="scss" scoped>

.left_aligned_container {
}

</style>
