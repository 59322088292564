<template>
  <div class="tokyo_tm_history">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>History</span>
          <h3>Timeline</h3>
        </div>
      </div>
    </div>
    <div class="timeline-container" id="timeline-container">
      <vue-timeline-update
          v-for="(item, index) in items" :key="index"

          :date="formatDate(item.date)"
          :title="item.title"
          :description="item.description"
          :thumbnail="item.thumbnail !== '' ? getThumbnail(item.thumbnail) : ''"
          :category="getCategoryAssets(item.category).name"
          :icon="getCategoryAssets(item.category).icon"
          :color="getCategoryAssets(item.category).color"
          :is-last="index === items.length-1"
          animation-container="timeline-container"
      />
    </div>
  </div>
</template>

<script>
import {TimelineCategory} from "../utils/TimelineCategory";
import data from '../assets/data/history.json';

export default {
  name: "History",
  data() {
    return {
      items: data,
    }
  },
  methods: {
    getCategoryAssets: function (category) {
      return TimelineCategory.getTimelineAsset(category);
    },
    getThumbnail: function (path) {
      if(path !== null || path!==""){
        return require(`@/assets/img/history/${path}`);
      }
      return null;
    },
    formatDate: function (date) {
      return new Date(date);
    }
  }
}
</script>

<style lang="scss" scoped>
.timeline-container {
  width: 100%;
  margin-top: 120px;
  position: absolute;
  padding-right: 20px;
}

@media (min-width: 768px) {
  .timeline-container {
    margin-left: -20px;
  }
}

.tokyo_tm_history {

}

</style>