<template>
  <div class="tokyo_tm_about">
    <div class="about_image">
      <img src="../assets/img/slider/big_screen_cult.jpg" alt="about" />
    </div>
    <!-- End .about_image -->

    <div class="description">
      <h3 class="name">YUENA -ユエナ-</h3>
      <div class="description_inner">
        <div class="left">
          <p>
            ボーカロイド・J-Pop・アニメソングなど、カバーさせて頂いております。
            元気な声してます。
          </p>
          <div class="tokyo_tm_button">
            <button class="ib-button" @click="showModal">Read More</button>
          </div>
        </div>
        <!-- End .left -->

        <div class="right">
          <ul>
            <li>
              <p><span>Birthday:</span>11月23日</p>
            </li>
            <li>
              <p>
                <span>Contact:</span
                >TwitterのDMにお願いします。
              </p>
            </li>
            <li>
              <p><span>Freelance:</span>上に同じです。</p>
            </li>
          </ul>
          <!-- End ul -->
        </div>
        <!-- End .right -->
      </div>
    </div>

    <!-- Start Modalbox -->
    <transition name="fade">
      <!-- Modal -->
      <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModal"
        v-if="isVisible"
      >
        <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
        >
          <div class="modal-content">
            <div class="tokyo_tm_modalbox_about">
              <div class="close" @click="closeModal">
                <img
                  class="svg"
                  src="../assets/img/svg/cancel.svg"
                  alt="cancel-img"
                />
              </div>
              <!-- End .close -->
              <div class="my_box">
                <div class="left">
                  <div class="about_title">
                    <h3>Covered Song Types</h3>
                  </div>
                  <!-- End .abut_title -->
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Vocaloid</span
                        ><span class="number">64%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:63%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->
                    <div class="progress_inner">
                      <span
                        ><span class="label">J-Pop</span
                        ><span class="number">36%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:36%"></div>
                        </div>
                      </div>
                    </div>
                    <!-- End progress_inner -->

                  </div>
                  <!-- End .tokyo_progress -->
                </div>
                <!-- End .left -->
                <div class="right">
                  <div class="about_title">
                    <h3>Graphics Skills</h3>
                  </div>
                  <div class="tokyo_progress">
                    <div class="progress_inner">
                      <span
                        ><span class="label">Character Drawing</span
                        ><span class="number">80%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:80%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Character Design</span
                        ><span class="number">75%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:75%"></div>
                        </div>
                      </div>
                    </div>
                    <div class="progress_inner">
                      <span
                        ><span class="label">Animation</span
                        ><span class="number">70%</span></span
                      >
                      <div class="background">
                        <div class="bar">
                          <div class="bar_in" style="width:70%"></div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <!-- End .right -->
              </div>
              <div class="counter">
                <div class="about_title">
                  <h3>Fun Facts</h3>
                </div>
                <ul>
                  <li>
                    <div class="list_inner">
                      <h3>110k+</h3>
                      <span>Views</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>40+</h3>
                      <span>Covers</span>
                    </div>
                  </li>
                  <li>
                    <div class="list_inner">
                      <h3>06-2019</h3>
                      <span>Till now</span>
                    </div>
                  </li>
                </ul>
                <!-- End ul -->
              </div>
              <!-- End .counter -->
            </div>
            <!-- End .tokyo_tm_modalbox_about -->
          </div>
        </div>
      </div>
    </transition>
    <!-- End Modalbox -->
  </div>
</template>

<script>
export default {
  data() {
    return {
      isActive: false,
      isVisible: false,
    };
  },
  methods: {
    showModal: function() {
      this.isActive = true;
      this.isVisible = true;
    },
    closeModal: function() {
      this.isActive = false;
      this.isVisible = false;
    },
  },
};
</script>

<style lang="scss"></style>
