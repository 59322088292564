<template>
  <div class="tokyo_tm_portfolio">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>Works</span>
          <h3>Creative</h3>
        </div>
        <!-- End Left -->
        <div class="portfolio_filter">
          <ul>
            <li>
              <a
                v-on:click="activetab = 1"
                :class="[activetab === 1 ? 'active' : '']"
                >Popular</a
              >
            </li>
            <!-- End All for tabcontent 1 -->
            <li>
              <a
                v-on:click="activetab = 2"
                :class="[activetab === 2 ? 'active' : '']"
                >Covers</a
              >
            </li>
            <!-- End Mix tabcontent 4 -->
            <li>
              <a
                v-on:click="activetab = 3"
                :class="[activetab === 3 ? 'active' : '']"
                >Illustrations</a
              >
            </li>
            <!-- End Gallery tabcontent 5 -->
          </ul>
        </div>
        <!-- End .portfolio-filter -->
      </div>
    </div>
    <!-- End tokyo_tm_title -->

    <div v-if="activetab === 1" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="allItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in allItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
              <pre>{{image.title}}</pre>
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 1 -->

    <div v-if="activetab === 2" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox :items="coverItems" :index="index" @close="index = null">
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in coverItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
              <pre>{{image.title}}</pre>
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 2 -->

    <div v-if="activetab === 3" class="tabcontent">
      <ul class="portfolio_list">
        <CoolLightBox
          :items="illustrationItems"
          :index="index"
          @close="index = null"
        >
        </CoolLightBox>
        <li
          v-for="(image, imageIndex) in illustrationItems"
          :key="imageIndex"
          @click="index = imageIndex"
        >
          <div class="inner">
            <div class="entry tokyo_tm_portfolio_animation_wrap">
              <img class="image" :src="image.thumb" alt="Portfolio" />
              <pre>{{image.title}}</pre>
            </div>
          </div>
        </li>
        <!-- End li -->
      </ul>
      <!-- End .portfolio_list -->
    </div>
    <!-- End .tabcontent 3 -->
  </div>
</template>

<script>
  export default {
    data() {
      return {
        activetab: 1,
        allItems: [
          {
            src: "https://www.youtube.com/watch?v=joCpUOYqPr8",
            thumb: require("@/assets/img/portfolio/ultragirl_yuena_urara.jpg"),
            title: '【七海うらら*×YUENA Collabo Cover】\n３分ガール／HIMEHINA',
          },
          {
            src: "https://www.youtube.com/watch?v=lxIjQ6gaUyk",
            thumb: require("@/assets/img/portfolio/zenbukiminoseida.jpg"),
            title: '【Cover】全部君のせいだ／イチョウ',
          },
          {
            src: "https://www.youtube.com/watch?v=rDLpyJthmOc",
            thumb: require("@/assets/img/portfolio/halloweennightparty_yuena_urara.jpg"),
            title: '【七海うらら*×YUENA Collabo Cover】\nハロウィンナイトパーティ／HoneyWorks',
          },
          {
            src: "https://www.youtube.com/watch?v=P_jP5_rJ2JU",
            thumb: require("@/assets/img/portfolio/cinderella.jpg"),
            title: '【Cover】シンデレラ／DECO*27',
          },
          {
            src: "https://www.youtube.com/watch?v=9476lbkqIM8",
            thumb: require("@/assets/img/portfolio/sislove_honeyworks.jpg"),
            title: '【YUENA×だんごむしむし Collabo Cover】\nシス×ラブ／HoneyWorks',
          },
          {
            src: "https://www.youtube.com/watch?v=O0_I7ol_xhQ",
            thumb: require("@/assets/img/portfolio/easygame_natsumi_yuena.jpg"),
            title: "【natsumi×YUENA Collabo Cover】\nイージーゲームfeat.和ぬか／natsumi",
          },
          {
            src: "https://www.youtube.com/watch?v=107qQYPj6nk",
            thumb: require("@/assets/img/portfolio/cult.jpg"),
            title: "【Cover】CULT／DUSTCELL",
          },
          {
            src: "https://www.youtube.com/watch?v=ZFhQtER_sc0",
            thumb: require("@/assets/img/portfolio/non-breath_oblige.jpg"),
            title: "【Cover】ノンブレス・オブリージュ／ピノキオピー",
          },/*
          {
            src: "https://www.youtube.com/watch?v=mWm6VBdxa88",
            thumb: require("@/assets/img/portfolio/vampire.jpg"),
            title: "【Cover】ヴァンパイア／DECO*27",
          },*/
        ],
        coverItems: [
          {
            src: "https://www.youtube.com/watch?v=6nNSENZexWg",
            thumb: require("@/assets/img/portfolio/nothingworkingout.jpg"),
            title: '【Cover】なにやってもうまくいかない/meiyo',
          },
          {
            src: "https://www.youtube.com/watch?v=FPXiaEMAPX0",
            thumb: require("@/assets/img/portfolio/easygame.jpg"),
            title: "【Cover】イージーゲームfeat.和ぬか／natsumi",
          },
          {
            src: "https://www.youtube.com/watch?v=CTD_kCmIogI",
            thumb: require("@/assets/img/portfolio/goodbye_declaration.jpg"),
            title: "【Cover】グッバイ宣言／Chinozo",
          },
          {
            src: "https://www.youtube.com/watch?v=N3g2NeQTLTU",
            thumb: require("@/assets/img/portfolio/mao.jpg"),
            title: "【Cover】マオ／かいりきベア×まふまふ",
          },
          {
            src: "https://www.youtube.com/watch?v=T_KoBeGkOBA",
            thumb: require("@/assets/img/portfolio/march9th.jpg"),
            title: "【Cover】3月9日／レミオロメン",
          },
          {
            src: "https://www.youtube.com/watch?v=7xSGzPM9i0s",
            thumb: require("@/assets/img/portfolio/silvousplaitgident.jpg"),
            title: "【Cover】シル・ヴ・プレジデント／P丸様。",
          },
          /*
          {
            src: "https://www.youtube.com/watch?v=iCuYus_RFlw",
            thumb: require("@/assets/img/portfolio/jelousies.jpg"),
            title: "【Cover】ジェラシス／Chinozo",
          },
          */
        ],
        illustrationItems: [
          {
            src: require("@/assets/img/portfolio/illust/cinderella_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/cinderella_yuena.jpg"),
            title: "シンデレラ YUENA ver.",
            description: "【Cover】シンデレラ／DECO*27 サムネイル",
            link: "https://www.youtube.com/watch?v=P_jP5_rJ2JU",
          },
          {
            src: require("@/assets/img/portfolio/illust/nonbreath_oblige_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/nonbreath_oblige_yuena.jpg"),
            title: "ノンブレス・オブリージュ YUENA ver.",
            description: "【Cover】ノンブレス・オブリージュ／ピノキオピー サムネイル",
            link: "https://www.youtube.com/watch?v=ZFhQtER_sc0",
          },
          {
            src: require("@/assets/img/portfolio/illust/nia_aomekabu.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/nia_aomekabu.jpg"),
            title: "【Commission】ニア 歌ってみた by 碧芽かぶ",
            description: "碧芽かぶさんの歌ってみた動画へのコミッション",
            link: "https://www.nicovideo.jp/watch/sm39066639",
          },
          {
            src: require("@/assets/img/portfolio/illust/mela_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/mela_yuena.jpg"),
            title: "Mela! YUENA ver. 2周年記念!",
            description: "【Cover】Mela!／緑黄色社会 動画/アニメーション",
            link: "https://www.youtube.com/watch?v=JED77vg7VmA",
          },
          {
            src: require("@/assets/img/portfolio/illust/goodbye_sengen_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/goodbye_sengen_yuena.jpg"),
            title: "グッバイ宣言 YUENA ver.",
            description: "【Cover】グッバイ宣言／Chinozo サムネイル",
            link: "https://www.youtube.com/watch?v=CTD_kCmIogI",
          },
          {
            src: require("@/assets/img/portfolio/illust/vampire_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/vampire_yuena.jpg"),
            title: "ヴァンパイア YUENA ver.",
            description: "【Cover】ヴァンパイア／DECO*27 サムネイル",
            link: "https://www.youtube.com/watch?v=mWm6VBdxa88",
          },
          {
            src: require("@/assets/img/portfolio/illust/mao_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/mao_yuena.jpg"),
            title: "マオ YUENA ver.",
            description: "【Cover】マオ／かいりきベア×まふまふ サムネイル",
            link: "https://www.youtube.com/watch?v=N3g2NeQTLTU",
          },
          {
            src: require("@/assets/img/portfolio/illust/gimme_gimme_umi_yuena.png"),
            thumb: require("@/assets/img/portfolio/illust/thumbnails/gimme_gimme_umi_yuena.jpg"),
            title: "Gimme×Gimme umi×YUENA",
            description: "【umi×YUENA Collabo Cover】Gimme×Gimme／八王子P×Giga 公開記念イラスト",
            link: "https://www.youtube.com/watch?v=HLMwI8zmhlg",
          },
        ],
        index: null,
      };
    },
  };
</script>

<style lang="scss" scoped>

.tokyo_tm_portfolio_animation_wrap{
  position: relative;
}

.tokyo_tm_portfolio_animation_wrap pre{
  position: absolute;
  color: #fff;
  text-align: center;
  width: 100%;
  padding: 12px 0;
  left: 0;
  bottom: 0;
  background: rgba(0,0,0,0.65);
  font-size: clamp(12px, 1vw, 15px);
}

</style>
