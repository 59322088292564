<template>
  <ul class="social">
    <li v-for="(social, i) in socialList" :key="i">
      <a :href="social.link" target="_blank" rel="noreferrer"
        ><img class="svg" :src="social.src" alt="social"
      /></a>
    </li>
  </ul>
  <!-- End. social -->
</template>

<script>
  export default {
    data() {
      return {
        socialList: [
          {
            link: "https://twitter.com/yuena_uta_",
            src: require("@/assets/img/svg/social/twitter.svg"),
          },
          {
            link: "https://www.youtube.com/channel/UCCAc0FIVQQhnDDy3gWvKIhA",
            src: require("@/assets/img/svg/social/youtube.svg"),
          },
          {
            link: "https://www.nicovideo.jp/user/89079172",
            src: require("@/assets/img/svg/social/niconico.svg"),
          },
          {
            link: "https://www.tiktok.com/@yuena_",
            src: require("@/assets/img/svg/social/tik-tok.svg"),
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
