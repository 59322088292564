<template>
  <transition name="fade">
    <div :class="{'modal-mask': isActive}"
         id="modal"
         @click="closeModalWindow(index)"
         v-if="isVisible">
      <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
      >
        <div class="modal-content-twitter">
          <div class="tokyo_tm_modalbox_news">
            <Tweet class="news-twitter" :id=getTweetId() :options="{ conversation: isContainedConversation() }"><div class="spinner"></div></Tweet>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import {Tweet} from 'vue-tweet-embed'

export default {
  components: {Tweet},
  name: "TwitterNews",
  data() {
    return {
    }
  },
  props: {
    index: {
      type: Number,
      default: -1,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    conversation:{
      type: Boolean,
      default: false,
    },
    tweetId:{
      type: String,
      default: -1,
    },
    closeModalWindow: {
      type: Function,
      default: null,
    },
  },
  methods:{
    isContainedConversation: function () {
      return this.conversation ? '' : 'none';
    },
    getTweetId: function (){
      return this.tweetId;
    }
  }
}
</script>

<style lang="scss" scoped>
.modal-dialog {
  /* max-width: 968px; */
  margin: 0px auto;
  padding: 0 30px;
  /* padding: 0 15px; */
}
.modal-content-twitter {
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 100%;
  pointer-events: auto;
  margin: 0 auto;
  /* background-color: #fff; */
  background-clip: padding-box;
  /*border: 1px solid rgba(0, 0, 0, 0.2);*/
  outline: 0;
  border-radius: 10px;
  box-shadow: none;
  margin-top: 80px;
  margin-bottom: 80px;
  overflow-y: auto;
}
.tokyo_tm_modalbox_news {
  margin: auto;
}
</style>