<template>
  <div class="list_inner">
    <div class="image">
      <img src="@/assets/img/thumbs/4-3.jpg" alt="thumb"/>
      <div
          class="main"
          :style="{ 'backgroundImage': 'url(' + getThumbnail + ')' }"
          @click="showModalWindow(index)"
      ></div>
    </div>
    <!-- End .image -->
    <div class="details">
      <div class="extra">
        <p class="date">
          via <a href="#">{{ source }}</a><span>{{ date }}</span>
        </p>
      </div>
      <!-- End meta info -->
      <h3 class="title" @click="showModalWindow(index)">
        {{ description }}
      </h3>
      <div class="tokyo_tm_read_more" @click="showModalWindow(index)">
        <a><span>Read More</span></a>
      </div>
    </div>
    <!-- End .details -->
  </div>
</template>

<script>
export default {
  name: "NewsItem",
  props: {
    index: {
      type: Number,
      default: -1,
    },
    source: {
      type: String,
      default: "Unknown",
    },
    date: {
      type: String,
      default: "Unknown",
    },
    description: {
      type: String,
      default: "No description",
    },
    showModalWindow: {
      type: Function,
      default: null,
    },
    thumbnail:{
      default: null
    },
  },
  computed: {
    getThumbnail() {
      if(this.thumbnail === null){
        return "";
      }
      if(this.thumbnail.useWebImage === true){
        return this.thumbnail.url;
      }
      if (this.thumbnail.path !== "") {
        return require(`@/assets/img/news/${this.thumbnail.path}`);
      }
      return "";
    }
  }
}
</script>

<style lang="scss" scoped>
body.dark .tokyo_tm_news ul li .list_inner {
  box-shadow: 0 0 2px #252525;
}
.tokyo_tm_news ul li .details {
  width: 100%;
  float: left;
  padding-top: 30px;
  padding-left: 40px;
  padding-right: 40px;
  min-height: 209px;
  border-top: 1px dashed #5e5e5e;
}
.extra {
  justify-content: unset !important;
  .date{
    width: 100%;
  }
}
</style>