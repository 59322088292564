export const TimelineCategory = {
  GENERAL: {
    val: 1,
    text: "General",
  },
  ACTIVITY: {
    val: 2,
    text: "Activity",
  },
  ANNOUNCEMENT: {
    val: 3,
    text: "Announcement"
  },
  SONG: {
    val: 4,
    text: "Song"
  },
  getTimelineAsset: function (category) {
    let asset = {
      color: "white",
      icon: "code",
      name: "category",
    };
    switch (category) {
      case TimelineCategory.GENERAL.val:
        asset.color = "black";
        asset.icon = "code";
        asset.name = TimelineCategory.GENERAL.text;
        break;
      case  TimelineCategory.ACTIVITY.val:
        asset.color = "red";
        asset.icon = "grade";
        asset.name = TimelineCategory.ACTIVITY.text;
        break;
      case TimelineCategory.ANNOUNCEMENT.val:
        asset.color = "blue";
        asset.icon = "speaker_notes";
        asset.name = TimelineCategory.ANNOUNCEMENT.text;
        break;
      case TimelineCategory.SONG.val:
        asset.color = "blue";
        asset.icon = "music_note";
        asset.name = TimelineCategory.SONG.text;
        break;
    }
    return asset;
  }
};
