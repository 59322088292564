<template>
  <transition name="fade">
    <div
        :class="{ 'modal-mask': isActive }"
        id="modal"
        @click="closeModalWindow(index)"
        v-if="isVisible"
    >
      <div
          class="modal-dialog modal-dialog-centered modal-dialog-scrollable modal-user"
          @click.stop
      >
        <div class="modal-content">
          <div class="tokyo_tm_modalbox_news">
            <div class="close" @click="closeModalWindow(index)">
              <img
                  class="svg"
                  src="../../assets/img/svg/cancel.svg"
                  alt="cancel-img"
              />
            </div>
            <!-- END CLOSE ICON -->
            <div class="image">
              <img src="../../assets/img/thumbs/4-3.jpg" alt="tumb" />
              <div
                  class="main"
                  :style="{ backgroundImage: 'url(' + popup1 + ')' }"
              ></div>
            </div>
            <!-- END IMAGE-->
            <div class="details">
              <div class="extra">
                <p class="date">
                  By <a href="#">Alex Watson</a>
                  <span>05 April 2021</span>
                </p>
              </div>
              <h3 class="title">
                Format releases a new tool that enables direct video
                hosting
              </h3>
            </div>
            <!-- END DETAILS -->
            <div class="main_content ">
              <div class="descriptions">
                <p class="bigger">
                  Just because we can't get out and about like we normally
                  would, doesn’t mean we have to stop taking pictures.
                  There’s still plenty you can do, provided you're
                  prepared to use some imagination. Here are a few ideas
                  to keep you shooting until normal life resumes.
                </p>
                <p>
                  Most photographers love to shoot the unusual, and you
                  don’t get much more unusual than These Unprecedented
                  Times. Right now everything counts as out of the
                  ordinary. There are a number of remarkable things about
                  these lockdown days that are worth photographing now so
                  we can remember them when it is all over.
                </p>
                <p>
                  Streets empty that are usually busy are remarkable and
                  can evoke the sense of historical pictures from before
                  the invention of the motorcar. Other things that are
                  different at the moment will be queues to get into
                  stores and the lines marked out on the floor to show how
                  far apart we should be.
                </p>
                <div class="quotebox">
                  <div class="icon">
                    <img
                        class="svg"
                        src="../../assets/img/svg/quote.svg"
                        alt="tumb"
                    />
                  </div>
                  <p>
                    Most photographers find it hard to see interesting
                    pictures in places in which they are most familiar. A
                    trip somewhere new seems always exactly what our
                    photography needed, as shooting away from home
                    consistently inspires us to new artistic heights.
                  </p>
                </div>
                <!-- END QUOTEBOX -->
                <p>
                  Pretend everything is new and that you haven’t seen it
                  before, and then you will be free to notice the leading
                  lines, the places where one edge meets another in
                  delightful geometric harmony, and how the ordinary
                  things in the kitchen are transformed when the light is
                  on or off.
                </p>
                <p>
                  The trick here is to look slowly, and then look again.
                  Take the time to look in detail and to look at the same
                  thing from different angles, with different light, long
                  lenses and wide lenses. Then move to the left a bit. You
                  may never feel the need to leave the house again.
                </p>
              </div>
              <!-- END DESCRIPTION -->
              <div class="news_share">
                <span>Share:</span>
                <Social />
                <!-- END SOCIAL SHARE -->
              </div>
              <!-- END NEWS SHARE -->
            </div>
          </div>
          <!-- END MODALBOX NEWS -->
        </div>
      </div>
    </div>
  </transition>
</template>

<script>
import Social from "../../components/Social";
export default {
  name: "ImageTextNews",
  data(){
    return {
      popup1: require("../../assets/img/news/1.jpg"),
    }
  },
  props: {
    index: {
      type: Number,
      default: -1,
    },
    isActive: {
      type: Boolean,
      default: false,
    },
    isVisible: {
      type: Boolean,
      default: false,
    },
    closeModalWindow: {
      type: Function,
      default: null,
    }
  },
  components: {
    Social,
  },
}
</script>

<style scoped>

</style>