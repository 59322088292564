export const NewsType = {
  Video:{
    val: 1,
    text: "Video",
  },
  Twitter: {
    val: 2,
    text: "Twitter",
  },
  Web: {
    val: 3,
    text: "Web"
  }
};
