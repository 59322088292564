<template>
  <div class="tokyo_tm_news">
    <div class="tokyo_tm_title">
      <div class="title_flex">
        <div class="left">
          <span>News</span>
          <h3>Latest News</h3>
        </div>
      </div>
    </div>
    <ul class="tokyo_tm_news_inner">
      <li v-for="(item, index) in items" :key="index">
        <NewsItem :index="index" :source="item.newsType.text" :date="item.date"
                  :description="item.description"
                  :thumbnail="item.thumbnail"
                  :show-modal-window="showModalWindow"/>
        <div v-if="item.newsType.val===2">
          <TwitterNews :index="index" :is-active="item.isActive" :is-visible="item.isVisible"
                       :tweetId="item.contents.id"
                       :conversation="item.contents.conversation"
                       :close-modal-window="closeModalWindow"/>
        </div>
        <div v-else>
          <ImageTextNews :index="index" :is-active="item.isActive" :is-visible="item.isVisible"
                         :close-modal-window="closeModalWindow"/>
        </div>
        <!-- End second modal -->
      </li>
      <!-- End single blog -->
    </ul>
  </div>
</template>

<script>
import {NewsType} from "../utils/NewsType";
import ImageTextNews from "./News/ImageTextNews";
import NewsItem from "./News/NewsItem";
import TwitterNews from "./News/TwitterNews";

export default {
  components: {TwitterNews, NewsItem, ImageTextNews},
  data() {
    return {
      items: [
        {
          newsType: NewsType.Twitter,
          date:'30 Nov. 2021',
          description: 'タウンハウジングのCMソングに起用！',
          isActive: false,
          isVisible: false,
          useWebImage: false,
          thumbnail:{
            useWebImage: true,
            url: "https://img.youtube.com/vi/YRz6NS_sPLU/maxresdefault.jpg",
          },
          contents:{
            id:"1465633505354805254",
            conversation: true,
          }
        },
        {
          newsType: NewsType.Twitter,
          date: '12 Oct. 2021',
          description: 'LINEのチャットボットを公開！',
          isActive: false,
          isVisible: false,
          useWebImage: false,
          thumbnail:{
            useWebImage: false,
            path:"20211012_linechatbot.jpg",
          },
          contents: {
            id:"1447600952244985860",
            conversation: true,
          }
        },
        {
          newsType: NewsType.Twitter,
          date: '18 Sep. 2021',
          description: 'だんごむしむしさんとのコラボカバー   シスｘラブ / HoneyWorks を公開！',
          isActive: false,
          isVisible: false,
          useWebImage: false,
          thumbnail:{
            useWebImage: false,
            path:"sislove_honeyworks.jpg",
          },
          contents: {
            id:"1439184928315486208",
            conversation: true,
          }
        },
        {
          newsType: NewsType.Twitter,
          date: '11 Sep. 2021',
          description: '楽曲カバー選手権「イージーゲーム」   グランプリ特典のコラボカバー公開！',
          isActive: false,
          isVisible: false,
          thumbnail:{
            useWebImage: false,
            path:"easygame_feat_natsumi.jpg",
          },
          contents: {
            id:"1436617783303757826",
            conversation: true,
          }
        },
        {
          newsType: NewsType.Twitter,
          date: '2 Aug. 2021',
          description: '2021年1月から約半年間のクロスフェードデモを公開！',
          isActive: false,
          isVisible: false,
          thumbnail:{
            useWebImage: false,
            path:"xfd_20210802.jpg",
          },
          contents: {
            id:"1422135083847360512",
            conversation: true,
          }
        },
        {
          newsType: NewsType.Twitter,
          date: '5 Jul. 2021',
          description: '楽曲カバー選手権「イージーゲーム」 グランプリ受賞！',
          isActive: false,
          isVisible: false,
          thumbnail:{
            useWebImage: true,
            url: "https://pbs.twimg.com/media/E5caWZOVoAYk0gL?format=jpg&name=large",
          },
          contents: {
            id:"1412003330390327302",
            conversation: true,
          }
        },
      ],

    };
  },
  methods: {
    showModalWindow: function (index) {
      this.items[index].isActive = true;
      this.items[index].isVisible = true;
    },
    closeModalWindow: function (index) {
      this.items[index].isActive = false;
      this.items[index].isVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
